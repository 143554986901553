'use strict';
import { cookiesBar, externalLink } from './modules';
import form from './forms/form';

import Swiper, { Navigation } from 'swiper';
import 'swiper/swiper-bundle.css';

import adminMenu from '@elasticms/admin-menu';
import '../css/app.scss';

const $ = require('jquery');
window.$ = $;
window.jQuery = $;
require('bootstrap');
Swiper.use([Navigation]);

adminMenu();
form();

const translations = JSON.parse(document.body.getAttribute('data-translations'));

cookiesBar.init('.js-cookies-bar');

(function () {
  $(document).ready(function () {
    externalLink(
      '<span class="sr-only">' +
        (translations.target_blank === undefined ? 'Back to ems' : translations.target_blank) +
        '</span>',
    );
    if ($('.search-results').length > 0) {
      history.replaceState({
        result: $('#selector_results').html(),
        filter: $('#pro-filter').html(),
      }, '', window.location)
      window.addEventListener("popstate", (event) => {
        $('#selector_results').html(event.state.result)
        const filters = $('#pro-filter')
        filters.html(event.state.filter)
        filters.find('.dropdown-menu.show').removeClass('show')
        filters.find('.loading').hide()
      })
    }

    const $backToSearch = $('.back-to-search');
    if ($backToSearch.length > 0) {
      const lastProfessionalsFilters = localStorage.getItem('last-professionals-filters')
      const currentUrl = $backToSearch.attr('href')
      const queryIndex = currentUrl.indexOf('?')
      if (queryIndex < 0) {
        return
      }
      const currentQuery = currentUrl.slice(queryIndex+1)
      if (!lastProfessionalsFilters.startsWith(currentQuery)) {
        return
      }

      $backToSearch.attr('href', currentUrl.slice(0, queryIndex+1) + lastProfessionalsFilters)
    }
  });

  // eslint-disable-next-line no-new
  new Swiper('#news-swiper', {
    slidesPerView: 1,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    spaceBetween: 30,
  });

  // eslint-disable-next-line no-new
  new Swiper('#quick-access-swiper', {
    slidesPerView: 1,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    spaceBetween: 30,
  });

  let collapseCounter = 0;
  const scrollTo = $('.wysiwyg-content[data-scroll-to]').data('scroll-to');
  $('.wysiwyg-content .collapse-item').each(function () {
    const $toggle = $(this).children().first();
    const $content = $(this).children().not(':first-child');

    const $newToggle = $(
      '<a href="#collapse-' +
        collapseCounter +
        '" class="text-decoration-none text-body" data-toggle="collapse" role="button" aria-expanded="false" aria-controls="collapse-' +
        collapseCounter +
        '">' +
        $toggle.text() +
        '</a>',
    );
    $toggle.html($newToggle);

    const $newContentBody = $('<div class="collapse-body"></div>');
    $newContentBody.append($content);
    const $newContent = $('<div class="collapse" id="collapse-' + collapseCounter + '"></div>');
    $newContent.append($newContentBody);

    $newContent.insertAfter($toggle);

    if (undefined !== scrollTo && scrollTo === $(this).attr('id')) {
      $newContent.collapse('show');
      $('html, body').animate(
        {
          scrollTop: $newContent.offset().top,
        },
        2000,
      );
    }

    collapseCounter++;
  });

  $(document).on('click', '.pro-filter .keep-open', function (e) {
    e.stopPropagation();
  });

  $(document).on('change', '#profession_all', function () {
    const $checkboxes = $(this).closest('.dropdown-menu').find('input[type="checkbox"]:not(#profession_all)');
    $checkboxes.prop('checked', $(this).is(':checked'));
    $checkboxes.first().trigger('change');
  });

  $(document).on('click', '.dropdown-action .dropdown-item', function () {
    const $dropdownButtonProfession = $('#dropdownMenuProfession');
    $dropdownButtonProfession.data('placeholder');
    $dropdownButtonProfession.addClass('disabled');

    const $dropdownButton = $('#dropdownMenuAction');
    $dropdownButton.text($(this).text());

    const index = $(this).data('index');
    const $dropdownProfession = $('.dropdown-profession');
    $dropdownProfession.data('index', index);

    const prototype = $dropdownProfession.data('prototype');
    const $dropdownPlaceholder = $('.dropdown-profession .scrollable');
    $dropdownPlaceholder.empty();
    $('#selector_results').empty();
    $dropdownButtonProfession.text($dropdownButtonProfession.data('placeholder'));

    // eslint-disable-next-line no-undef
    for (let i = 0; i < jobs[index].length; i++) {
      // eslint-disable-next-line no-undef
      const job = jobs[index][i];
      const jobHtml = prototype.replace(/__OUUID__/g, job.ouuid).replace(/__TITLE__/g, job.title);

      $dropdownPlaceholder.append(jobHtml);
    }
    $('#profession_all').prop('checked', false);
    $dropdownButtonProfession.removeClass('disabled');

    updateURL();
  });

  function jobHandler() {
    const $selectedCheckboxes = $('.dropdown-profession input[type="checkbox"]:not(#profession_all):checked');
    const selected = $selectedCheckboxes.length;
    const $dropdownButton = $('#dropdownMenuProfession');

    const $loading = $('.pro-filter .loading');

    if (selected === 0) {
      $dropdownButton.text($dropdownButton.data('placeholder'));
    } else if (selected === 1) {
      $dropdownButton.text($selectedCheckboxes.next().text());
    } else {
      $dropdownButton.text($dropdownButton.data('placeholder-selected-plural').replace('%d', selected));
    }
    const $dropdownProfession = $('.dropdown-profession');
    let url = $dropdownProfession.data('url');
    const index = $dropdownProfession.data('index');

    url = url.replace('__CHANGEME__', index);

    const professionsIds = [];
    $selectedCheckboxes.each(function () {
      professionsIds.push($(this).attr('id'));
    });

    const $selectorResults = $('#selector_results');
    if (selected > 0) {
      $loading.show();
      $.get(url, { id: professionsIds }, function (response) {
        $loading.hide();
        $selectorResults.empty();
        $selectorResults.append(response.html);
        $('#selector_results_number').text($('#selector_results tr').length);

        $('.search-hit-link').each(function () {
          const href = $(this).attr('href');
          if (!href.endsWith('?h=1')) {
            $(this).attr('href', href + '?h=1');
          }
        });

        // on hold
        // paginateSearchHits();
        adminMenu();
      });
    } else {
      $selectorResults.empty();
    }

    updateURL();
  }

  function updateURL() {
    const index = $('.dropdown-profession').data('index');
    const $selectedCheckboxes = $('.dropdown-profession input[type="checkbox"]:not(#profession_all):checked');

    const url = new URL(window.location);
    url.searchParams.set('what', index);

    url.searchParams.delete('for[]');
    if ($selectedCheckboxes.length) {
      $selectedCheckboxes.each(function () {
        url.searchParams.append('for[]', $(this).attr('id'))
      });
    }

    window.history.pushState({
      result: $('#selector_results').html(),
      filter: $('#pro-filter').html(),
    }, '', url);

    localStorage.setItem('last-professionals-filters', url.searchParams.toString())
  }

  $(document).on('change', '.dropdown-profession input[type="checkbox"]:not(#profession_all)', jobHandler);
})();
